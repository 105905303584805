import { Fragment } from 'react'
import './footer.styles.css'
import { Outlet } from 'react-router-dom'

import logo from '../../assets/images/logo-04.png'

const Footer = () => {
  return (
    <Fragment>
      <Outlet />
      <footer id='footer'>
        <div className='themesflat-container'>
          <div className='row'>
            <div className='col-12'>
              <div className='footer-content flex flex-grow'>
                <div className='widget-logo flex-grow'>
                  <div className='logo-footer' id='logo-footer'>
                    <a href='/'>
                      <img id='logo_footer' src={logo} alt='Logo' />
                    </a>
                  </div>
                </div>
                <div className='widget widget-menu style-1'>
                  <h5 className='title-widget'>زودگیفت</h5>
                  <ul>
                    <li>
                      <a href='#'>محصولات</a>
                    </li>
                    <li>
                      <a href='#'>گیفت گارت</a>
                    </li>
                    <li>
                      <a href='#'>اشتراک اکانت</a>
                    </li>
                    <li>
                      <a href='#'>ارتباط با ما</a>
                    </li>
                    <li>
                      <a href='#'>درباره ما</a>
                    </li>
                    <li>
                      <a href='#'>وبلاگ</a>
                    </li>
                  </ul>
                </div>
                <div className='widget widget-menu style-2'>
                  <h5 className='title-widget'>گیفت کارت</h5>
                  <ul>
                    <li>
                      <a href='#'>گبفت کارت اپل</a>
                    </li>
                    <li>
                      <a href='#'>گبفت کارت سونی</a>
                    </li>
                    <li>
                      <a href='#'>گبفت کارت ایکس باکس</a>
                    </li>
                    <li>
                      <a href='#'>گبفت کارت نینتندو</a>
                    </li>
                    <li>
                      <a href='#'>گبفت کارت گوگل</a>
                    </li>
                    <li>
                      <a href='#'>گبفت کارت مایکروسافت</a>
                    </li>
                  </ul>
                </div>
                <div className='widget widget-menu style-3'>
                  <h5 className='title-widget'>اشتراک اکانت</h5>
                  <ul>
                    <li>
                      <a href='#'> اشتراک تلگرام</a>
                    </li>
                    <li>
                      <a href='#'>اشتراک اسپاتیفای</a>
                    </li>
                    <li>
                      <a href='#'>اشتراک آمازون</a>
                    </li>
                    <li>
                      <a href='#'>اشتراک شاپیفای</a>
                    </li>
                    <li>
                      <a href='#'>اشتراک اپل موزیک</a>
                    </li>
                    <li>
                      <a href='#'>اشتراک ساندکلود ماهانه</a>
                    </li>
                  </ul>
                </div>
                <div className='widget-last'>
                  <div className='widget-menu style-4'>
                    <h5 className='title-widget'>مقالات</h5>
                    <ul>
                      <li>
                        <a href='#'>اخبار</a>
                      </li>
                      <li>
                        <a href='#'>آموزش</a>
                      </li>
                    </ul>
                  </div>
                  <h5 className='title-widget mt-30'>شبکه های اجتماعی</h5>
                  <div className='widget-social'>
                    <ul className='flex'>
                      <li>
                        <a href='#' className='icon-facebook'></a>
                      </li>
                      <li>
                        <a href='#' className='icon-twitter'></a>
                      </li>
                      <li>
                        <a href='#' className='icon-vt'></a>
                      </li>
                      <li>
                        <a href='#' className='icon-tiktok'></a>
                      </li>
                      <li>
                        <a href='#' className='icon-youtube'></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='footer-bottom'>
            <p>
              © 2025 Zoodgift - Made By{' '}
              <a href='https://senpaicoders.com/'>senpaiCoders</a>
            </p>
            <ul className='flex'>
              <li>
                <a href='#'>قواننین و مقررات</a>
              </li>
              <li>
                <a href='#'>شرایط و خدمات</a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </Fragment>
  )
}

export default Footer
