import React from 'react'
import img from '../../assets/images/blog/blog-detail-04.png'

const BlogGrid = () => {
  return (
    <div>
      {/* title */}
      <div className='flat-title-page'>
        <div className='themesflat-container'>
          <div className='row'>
            <div className='col-12'>
              <h1 className='heading text-center'>وبلاگ</h1>
              {/* <ul className='breadcrumbs flex justify-center'>
                <li className='icon-keyboard_arrow_right'>
                  <a href='index.html'>Home</a>
                </li>
                <li>
                  <a href='#'>Explore</a>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </div>

      {/* blog list */}
      <div className='tf-section tf-grid-blog pb-80'>
        <div className='themesflat-container'>
          <div className='row'>
            {[
              {
                imgSrc: img,
                category: 'اخبار',
                date: 'Mon, 08 May',
                title:
                  'تصور کنید با یک هواپیما به یک جزیره دورافتاده‌ای می‌روید ',
                authorImg: img,
                authorName: 'رضا عبادی',
                detailLink: 'blog-detail.html',
                authorLink: 'author-2.html',
              },
              {
                imgSrc: img,
                category: 'اخبار',
                date: 'Mon, 08 May',
                title: 'گیفت کارت چیست؟ آشنایی با معروف‌ترین گیفت‌کارت‌ها!',
                authorImg: img,
                authorName: 'رضا عبادی',
                detailLink: 'blog-detail.html',
                authorLink: 'author-2.html',
              },
              {
                imgSrc: img,
                category: 'آموزشی',
                date: 'Mon, 08 May',
                title: 'ولورانت چیست: همه چیز در مورد این بازی محبوب!',
                authorImg: img,
                authorName: 'رضا عبادی',
                detailLink: 'blog-detail.html',
                authorLink: 'author-2.html',
              },
              // Add other items here following the same structure
            ].map((blog, index) => (
              <div className='fl-blog fl-item2 col-lg-4 col-md-6' key={index}>
                <article className='tf-card-article'>
                  <div className='card-media'>
                    <a href={blog.detailLink}>
                      <img src={blog.imgSrc} alt={blog.title} />
                    </a>
                  </div>
                  <div className='meta-info flex'>
                    <div className={`item art active`}>{blog.category}</div>
                    <div className='item date'>{blog.date}</div>
                  </div>
                  <div className='card-title'>
                    <h5>
                      <a href={blog.detailLink}>{blog.title}</a>
                    </h5>
                  </div>
                  <div className='card-bottom flex items-center justify-between'>
                    <div className='author flex items-center justify-between'>
                      <div className='avatar'>
                        <img src={blog.authorImg} alt={blog.authorName} />
                      </div>
                      <div className='info'>
                        <span>نویسنده :</span>
                        <h6>
                          <a href={blog.authorLink}>{blog.authorName}</a>
                        </h6>
                      </div>
                    </div>
                    <a className='link' href='/blog-details'>
                      <i className='icon-arrow-up-right2'></i>
                    </a>
                  </div>
                </article>
              </div>
            ))}

            {/* Load More Button */}
            <div className='col-md-12 load-more'>
              <a
                href='blog-grid-1.html'
                id='loadmore'
                className='tf-button-loadmore'
              >
                <span>Load More</span>
                <i className='icon-loading-1'></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogGrid
