import './search-box.styles.css'
import { FaSearch } from "react-icons/fa";

const SearchBox = () => {
  return (
    <div className="search-box wow fadeInUp">
        <input placeholder='دنبال چی میگردی ؟ ' type="text" className="searh-box-input" />
        <span className="search-box-icon">
            <FaSearch />
        </span>
    </div>
  )
}

export default SearchBox