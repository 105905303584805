import React from 'react'
import img from '../../assets/images/avatar/avatar-01.png'

const BlogDetailTitlePage = () => {
  return (
    <div>
      {/* map site */}
      <div className='flat-title-page blog-detail'></div>

      {/* content */}

      <div className='tf-section tf-blog-detail pb-48'>
        <div className='themesflat-container'>
          <div className='row'>
            <div className='wrapper col-md-8'>
              <div className='inner-content mr-20'>
                <h2 className='title-post'>
                  استارز تلگرام چیست و چگونه آن را تهیه کنیم؟
                </h2>
                <div className='meta-post flex justify-between mt-10 items-center'>
                  <div className='author flex items-center justify-between'>
                    <div className='avatar'>
                      <img src={img} alt='blog' />
                    </div>
                    <div className='info'>
                      <span>نویسنده :</span>
                      <h6>
                        <a href='author-2.html'>رضا عبادی</a>{' '}
                      </h6>
                    </div>
                  </div>
                  <div className='meta-info flex'>
                    <div className='item art active'>اخبار</div>
                    <div className='item date'>Mon, 08 May</div>
                    <div className='item comment'>0 Comment</div>
                  </div>
                </div>
                <div className='divider'></div>
                <div className='title'>
                  تصور کنید در پلتفرمی که هر روز از آن استفاده می‌کنید، ارزی
                  دیجیتال وجود داشته باشد که شما را قادر سازد محتواهای انحصاری
                  را بخرید، از کانال‌های محبوب خود حمایت کنید، و حتی شانس خود را
                  در ایردراپ‌های پرسود افزایش دهید
                </div>
                <div className='image'>
                  <img src={img} alt='blog' />
                </div>
                <div className='inner-post'>
                  <h3 className='heading'>
                    این ایده دیگر تنها یک رویا نیست؛ بلکه به حقیقت پیوسته است.
                    تلگرام استارز، ارز دیجیتالی درون برنامه‌ای تلگرام، به شما
                    این امکان را می‌دهد که به دنیای جدیدی از فرصت‌ها و تعاملات
                    دیجیتال قدم بگذارید.
                  </h3>
                  <p>
                    این ایده دیگر تنها یک رویا نیست؛ بلکه به حقیقت پیوسته است.
                    تلگرام استارز، ارز دیجیتالی درون برنامه‌ای تلگرام، به شما
                    این امکان را می‌دهد که به دنیای جدیدی از فرصت‌ها و تعاملات
                    دیجیتال قدم بگذارید.
                  </p>
                  <blockquote>
                    <span className='icon-quote'></span>
                    <p>
                      این ایده دیگر تنها یک رویا نیست؛ بلکه به حقیقت پیوسته است.
                      تلگرام استارز، ارز دیجیتالی درون برنامه‌ای تلگرام، به شما
                      این امکان را می‌دهد که به دنیای جدیدی از فرصت‌ها و تعاملات
                      دیجیتال قدم بگذارید.
                    </p>
                  </blockquote>
                  <div className='image-box'>
                    <img src={img} alt='blog' />
                  </div>
                  <p>
                    این ایده دیگر تنها یک رویا نیست؛ بلکه به حقیقت پیوسته است.
                    تلگرام استارز، ارز دیجیتالی درون برنامه‌ای تلگرام، به شما
                    این امکان را می‌دهد که به دنیای جدیدی از فرصت‌ها و تعاملات
                    دیجیتال قدم بگذارید.
                  </p>
                </div>
                <div className='inner-post style-1'>
                  <h3 className='heading '>
                    این ایده دیگر تنها یک رویا نیست؛ بلکه به حقیقت پیوسته است.
                    تلگرام استارز، ارز دیجیتالی درون برنامه‌ای تلگرام، به شما
                    این امکان را می‌دهد که به دنیای جدیدی از فرصت‌ها و تعاملات
                    دیجیتال قدم بگذارید.
                  </h3>
                  <p>
                    امنیت، سرعت، و راحتی در کنار پشتیبانی حرفه‌ای، موبوگیفت را
                    به بهترین گزینه برای خرید تلگرام استارز تبدیل کرده است. اگر
                    آماده‌اید تا تلگرام را از یک پیام‌رسان ساده به بستری برای
                    تعاملات مالی و تجربه‌های دیجیتال پیشرفته تبدیل کنید، همین
                    حالا اولین قدم را بردارید.
                  </p>
                  <div className='image-box'>
                    <div className='w-full'>
                      <img src={img} alt='blog' />
                    </div>
                    <div className='w-full'>
                      <img src={img} alt='blog' />
                    </div>
                  </div>
                  <p>
                    این ایده دیگر تنها یک رویا نیست؛ بلکه به حقیقت پیوسته است.
                    تلگرام استارز، ارز دیجیتالی درون برنامه‌ای تلگرام، به شما
                    این امکان را می‌دهد که به دنیای جدیدی از فرصت‌ها و تعاملات
                    دیجیتال قدم بگذارید.
                  </p>
                  <p>
                    تلگرام استارز در دنیای ایردراپ‌ها نقش تعیین‌کننده‌ای ایفا
                    می‌کند. با استفاده از این ارز، شما می‌توانید شانس خود را در
                    دریافت توکن‌های رایگان در ایردراپ‌ها افزایش دهید و در مسیر
                    کسب سود در اکوسیستم تلگرام قدم بگذارید.
                  </p>
                </div>
                <div className='divider style-1'></div>
                <div className='bottom flex justify-between items-center'>
                  <div className='widget-tag flex items-center'>
                    <h3 className='title-widget'>Tags:</h3>
                    <ul className='flex flex-wrap gap4 items-center'>
                      <li>
                        <a href='/'>اخبار</a>
                      </li>
                      <li>
                        <a href='/'>آموزش</a>
                      </li>
                      <li>
                        <a href='/'>خبر</a>
                      </li>
                      <li>
                        <a href='/'>عنوان</a>
                      </li>
                    </ul>
                  </div>
                  <div className='widget-social'>
                    <ul className='flex'>
                      <li>
                        <a href='/' className='icon-facebook'></a>
                      </li>
                      <li>
                        <a href='/' className='icon-twitter'></a>
                      </li>
                      <li>
                        <a href='/' className='icon-linkedin2'></a>
                      </li>
                      <li>
                        <a href='/' className='icon-instagram'></a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='related-post'></div>
                <div className='widget-comment'>
                  <h3>نظر کاربران : (5)</h3>
                  <ul>
                    <li className='comment-box'>
                      <img src={img} alt='' />
                      <div className='comment-right'>
                        <div className='top flex justify-between items-center'>
                          <div className='info'>
                            <h5>Annette Black</h5>
                          </div>
                          <span>Mon, 08 May </span>
                        </div>
                        <p className=''>
                          Nullam ornare a magna quis aliquet. Duis suscipit eros
                          in suscipit venenatis. Pellentesque quis efficitur
                          leo. Maecenas accumsan est in nibh interdum, quis
                          dignissim neque scelerisque.
                        </p>
                        <a href='#'>Reply</a>
                      </div>
                    </li>
                    <li className='comment-box rep'>
                      <img src={img} alt='' />
                      <div className='comment-right'>
                        <div className='top flex justify-between items-center'>
                          <div className='info'>
                            <h5>Annette Black</h5>
                          </div>
                          <span>Mon, 08 May </span>
                        </div>
                        <p className=''>
                          Nullam ornare a magna quis aliquet. Duis suscipit eros
                          in suscipit venenatis. Pellentesque quis efficitur
                          leo. Maecenas accumsan est in nibh interdum, quis
                          dignissim neque scelerisque.
                        </p>
                        <a href='#'>Reply</a>
                      </div>
                    </li>
                    <li className='comment-box'>
                      <img
                        src='assets/images/avatar/avatar-user-03.jpg'
                        alt=''
                      />
                      <div className='comment-right'>
                        <div className='top flex justify-between items-center'>
                          <div className='info'>
                            <h5>Annette Black</h5>
                          </div>
                          <span>Mon, 08 May </span>
                        </div>
                        <p className=''>
                          Nullam ornare a magna quis aliquet. Duis suscipit eros
                          in suscipit venenatis. Pellentesque quis efficitur
                          leo. Maecenas accumsan est in nibh interdum, quis
                          dignissim neque scelerisque.
                        </p>
                        <a href='#'>Reply</a>
                      </div>
                    </li>
                    <li className='comment-box'>
                      <img src={img} alt='' />
                      <div className='comment-right'>
                        <div className='top flex justify-between items-center'>
                          <div className='info'>
                            <h5>Annette Black</h5>
                          </div>
                          <span>Mon, 08 May </span>
                        </div>
                        <p className=''>
                          Nullam ornare a magna quis aliquet. Duis suscipit eros
                          in suscipit venenatis. Pellentesque quis efficitur
                          leo. Maecenas accumsan est in nibh interdum, quis
                          dignissim neque scelerisque.
                        </p>
                        <a href='#'>Reply</a>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className='widget-reply'>
                  <h3 className='heading'>دیدگاه خود را بنویسید</h3>
                  <p>بحث درباره این مقاله را شما آغاز کنید!</p>
                  <form id='commentform' className='comment-form'>
                    <div className='flex justify-between'>
                      <fieldset className='name'>
                        <input
                          type='text'
                          id='name'
                          placeholder='* نام خود را وارد کنید'
                          className='style-1'
                          name='name'
                          tabindex='2'
                          value=''
                          aria-required='true'
                          required
                        />
                      </fieldset>
                      <fieldset className='email'>
                        <input
                          type='email'
                          id='email'
                          placeholder='آدرس ایمیل*'
                          className='style-1'
                          name='email'
                          tabindex='2'
                          value=''
                          aria-required='true'
                          required
                        />
                      </fieldset>
                    </div>
                    <fieldset className='message'>
                      <textarea
                        id='message'
                        name='message'
                        rows='4'
                        placeholder='نظر خود را بنویسید'
                        className='style-1 m-0'
                        tabindex='4'
                        aria-required='true'
                        required
                      ></textarea>
                    </fieldset>
                    <div className='btn-submit text-center'>
                      <button className='' type='submit'>
                        ارسال
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='side-bar col-md-4'>
              <div class='widget-search'>
                <form
                  action='#'
                  method='get'
                  role='search'
                  class='search-form relative'
                >
                  <input
                    type='search'
                    id='search'
                    class='search-field style-1'
                    placeholder='Search...'
                    value=''
                    name='s'
                    title='Search for'
                    required=''
                  />
                  <button
                    class='search search-submit'
                    type='submit'
                    title='Search'
                  >
                    <i class='icon-search'></i>
                  </button>
                </form>
              </div>
              <div className='widget widget-categories'>
                <h5 className='title-widget'>دسته بندی</h5>
                <ul>
                  <li>
                    <div className='cate-item'>
                      <a href='#'>اخبار</a>
                    </div>
                    <div className='number'>(1.483)</div>
                  </li>
                  <li>
                    <div className='cate-item'>
                      <a href='#'>آموزش</a>
                    </div>
                    <div className='number'>(97)</div>
                  </li>
                  <li>
                    <div className='cate-item'>
                      <a href='#'>راهنمای استفاده</a>
                    </div>
                    <div className='number'>(45)</div>
                  </li>
                  <li>
                    <div className='cate-item'>
                      <a href='#'>تکنولوژی</a>
                    </div>
                    <div className='number'>(728)</div>
                  </li>
                </ul>
              </div>
              <div className='widget widget-related-posts'>
                <h5 className='title-widget'>پست های مرتبط</h5>
                <div className='related-posts-item main'>
                  <div className='card-media'>
                    <img src={img} alt='' />
                  </div>
                  <div className='meta-info flex'>
                    <div className='item art active'>اخبار</div>
                    <div className='item date'>Mon, 08 May </div>
                  </div>
                  <h5>
                    <a href='nft-detail-2.html'>
                      امنیت، سرعت، و راحتی در کنار پشتیبانی حرفه‌ای، موبوگیفت را
                      به بهترین گزینه برای
                    </a>
                  </h5>
                </div>
                <div className='related-posts-item'>
                  <div className='card-media'>
                    <img src={img} alt='' />
                  </div>
                  <div className='card-content'>
                    <h5>
                      <a href='nft-detail-2.html'>
                        EA Play چیست؟ راهنمای کامل برای گیمرهای ایرانی
                      </a>
                    </h5>
                    <div className='item date'>Mon, 08 May </div>
                  </div>
                </div>
                <div className='related-posts-item'>
                  <div className='card-media'>
                    <img src={img} alt='' />
                  </div>
                  <div className='card-content'>
                    <h5>
                      <a href='nft-detail-2.html'>
                        ولورانت چیست: همه چیز در مورد این بازی محبوب!
                      </a>
                    </h5>
                    <div className='item date'>Mon, 08 May </div>
                  </div>
                </div>
                <div className='related-posts-item'>
                  <div className='card-media'>
                    <img src={img} alt='' />
                  </div>
                  <div className='card-content'>
                    <h5>
                      <a href='nft-detail-2.html'>
                        آموزش استفاده از گیفت کارت رادیوجوان
                      </a>
                    </h5>
                    <div className='item date'>Mon, 08 May </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogDetailTitlePage
