import { Fragment } from 'react';
import './faq-section.styles.css';

const FaqSection = () => {
    return (
        <Fragment>
            <div className='page-title faqs'>
                <div className='themesflat-container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h1
                                data-wow-delay='0s'
                                className='wow fadeInUp heading text-center'
                            >
                                سوالات متداول
                            </h1>
                            <p data-wow-delay='0.1s' className='wow fadeInUp'>
                                با سایت فروش گیفت کارت، دنیایی از با خرید آسان، تحویل سریع و
                                پشتیبانی ۲۴ ساعته، انتخاب‌ها و امکانات ؟ <br />
                                در دستان شماست! <span>روشگاه‌های جهانی و اشتراک‌های</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='tf-section-2 wrap-accordion'>
                <div className='themesflat-container w730'>
                    <div className='row'>
                        <div className='col-md-12 mb-20'>
                            <div className='flat-accordion2'>
                                {[
                                    {
                                        title: 'گیفت کارت چیست و چه کاربردی دارد ؟',
                                        content:
                                            'گیفت کارت‌ها کدهای دیجیتالی هستند که به شما امکان خرید محصولات یا استفاده از خدمات در پلتفرم‌های مختلف مانند بازی‌های آنلاین، فروشگاه‌های دیجیتال و اشتراک‌های سرگرمی را می‌دهند.',
                                    },
                                    {
                                        title: 'چگونه می‌توانم یک گیفت کارت خریداری کنم ؟ ',
                                        content:
                                            'با ورود به سایت ما، گیفت کارت مورد نظر خود را انتخاب کرده، مبلغ آن را تعیین کنید و پرداخت را به صورت آنلاین انجام دهید. کد گیفت کارت بلافاصله برای شما ارسال می‌شود.',
                                    },
                                    {
                                        title: 'چگونه از گیفت کارت استفاده کنم ؟',
                                        content:
                                            'کد گیفت کارت را پس از دریافت در بخش مربوطه از حساب کاربری پلتفرم مورد نظر وارد کنید تا اعتبار به حساب شما اضافه شود.',
                                        active: true,
                                    },
                                    {
                                        title: 'آیا گیفت کارت‌ها تاریخ انقضا دارند ؟ ',
                                        content:
                                            'برخی از گیفت کارت‌ها تاریخ انقضا دارند، اما این موضوع به نوع گیفت کارت و شرکت صادرکننده آن بستگی دارد. لطفاً توضیحات محصول را پیش از خرید بررسی کنید.',
                                    },
                                    {
                                        title:
                                            'آیا امکان بازگشت وجه برای گیفت کارت وجود دارد ؟ ',
                                        content:
                                            'به دلیل ماهیت دیجیتالی و قابل مصرف بودن گیفت کارت‌ها، امکان بازگشت وجه یا تغییر سفارش پس از خرید وجود ندارد.',
                                    },
                                    {
                                        title:
                                            'آیا می‌توان گیفت کارت‌ها را به دیگران هدیه داد ؟ ',
                                        content:
                                            'بله، گیفت کارت‌ها گزینه‌ای عالی برای هدیه دادن هستند. شما می‌توانید کد گیفت کارت را به دوستان یا عزیزانتان ارسال کنید.',
                                    },
                                    {
                                        title:
                                            'چه روش‌های پرداختی برای خرید گیفت کارت در دسترس است ؟ ',
                                        content:
                                            'ما از روش‌های پرداخت مختلف، از جمله کارت‌های بانکی معتبر و درگاه‌های امن پرداخت آنلاین پشتیبانی می‌کنیم.',
                                    },
                                    {
                                        title: ' آیا خرید گیفت کارت امن است',
                                        content:
                                            'بله، تمامی تراکنش‌ها در سایت ما با بالاترین استانداردهای امنیتی انجام می‌شوند و اطلاعات شما به صورت محرمانه نگهداری می‌شود.',
                                    },
                                ].map((item, index) => (
                                    <div
                                        key={index}
                                        data-wow-delay='0s'
                                        className={`wow fadeInUp flat-toggle2 ${item.active ? 'active' : ''
                                            }`}
                                    >
                                        <h6 className='toggle-title'>{item.title}</h6>
                                        <div
                                            className='toggle-content'
                                            style={{ display: item.active ? 'block' : 'none' }}
                                        >
                                            <p>{item.content}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default FaqSection