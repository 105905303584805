import  './gift-card.styles.css';

const GiftCard = ({cat}) => {
  return (
    <div
    data-wow-delay={cat.delay}
    className='wow fadeInUp col-xl-3 col-lg-4 col-md-6 col-sm-6'
>
    <div className='tf-card-box style-1'>
        <div className='card-media'>
            <a href='#' className="gift-card-img-link" >
                <img src={cat.img} alt={cat.name} />
            </a>
            <span className='wishlist-button icon-heart'></span>
            <div className='featured-countdown'>
                <span
                    className='js-countdown'
                    data-timer='7500'
                    data-labels='d,h,m,s'
                ></span>
            </div>
            <div className='button-place-bid'>
                <a
                    href='/'
                    data-toggle='modal'
                    data-target='#popup_bid'
                    className='tf-button'
                >
                    <span>خرید محصول</span>
                </a>
            </div>
        </div>
        <h5 className='name'>
            <a href='nft-detail-2.html'>{cat.name}</a>
        </h5>
        <div className='author flex items-center'>
            <div className='avatar'>
                <img src={cat.avatar} alt='Avatar' />
            </div>
            <div className='info'>
                <span>مدت زمان تحویل :</span>
                <h6>
                    <a href='author-2.html'>{cat.postedBy}</a>
                </h6>
            </div>
        </div>
    </div>
</div>
  )
}

export default GiftCard;