import { Fragment } from 'react'
import './navigation.styles.css'

import { IoCall } from 'react-icons/io5'
import { Outlet } from 'react-router-dom'


import Profile from "../../assets/images/profile-image.png"

import logo from '../../assets/images/logo-04.png'

const Navigation = () => {
  return (
    <Fragment>
      <header id='header_main' className='header_1 header-fixed'>
        {/* ------------------------------------------------ */}
        <div className='themesflat-container'>
          <div className='row'>
            <div className='col-md-12'>
              <div id='site-header-inner'>
                <div className='wrap-box flex nav-row-wrapper'>
                  {/* --logo-- */}
                  <div id='site-logo'>
                    <div id='site-logo-inner'>
                      {/* <a href='/' rel='home' className='main-logo'>
                        <img
                      id='logo_header'
                      src={logo}
                      alt='logo-header'
                    />
                      </a> */}
                    </div>
                  </div>
                  {/* <!-- logo --> */}
                  <div className='mobile-button'>
                    <span></span>
                  </div>
                  {/* <!-- /.mobile-button --> */}
                  <nav id='main-nav' className='main-nav'>
                    <ul id='menu-primary-menu' className='menu'>
                      <li className='menu-item'>
                        <a
                          className='font-family-iranyekan'
                          href='about-us.html'
                        >
                          درباره ما
                        </a>
                      </li>
                      <li className='menu-item '>
                        <a className='font-family-iranyekan'>محصولات</a>
                      </li>
                      <li className='menu-item'>
                        <a href='/blog-list' className='font-family-iranyekan'>
                          وبلاگ
                        </a>
                      </li>
                      <li className='menu-item'>
                        <a
                          className='font-family-iranyekan'
                          href='contact-us.html'
                        >
                          تماس با ما
                        </a>
                      </li>
                      <li className='menu-item'>
                        <a
                          className='font-family-iranyekan'
                          href='contact-us.html'
                        >
                          راهنمای استفاده
                        </a>
                      </li>
                      <li className='menu-item current-menu-item '>
                        <a className='font-family-iranyekan '>خانه</a>
                      </li>
                    </ul>
                  </nav>
                  {/* <!-- /#main-nav --> */}
                  <div className='flat-wallet flex'>
                        <div className="header-profile-box">
                          <img src={Profile} alt="profile" />
                        </div>
                    <div className='canvas'>
                      <span></span>
                    </div>
                    <div className='' id='wallet-header'>
                      <a
                        href='market-wallet.html'
                        id='connectbtn'
                        className='tf-button style-1 font-family-iranyekan'
                      >
                        <span>تماس با ما</span>
                        <IoCall className='call-icon' />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------------------------ */}
        {/* ------------------------------------------------ */}
        <div className='canvas-nav-wrap'>
          <div className='overlay-canvas-nav'></div>
          <div className='inner-canvas-nav'>
            <div className='side-bar'>
              <a href='index.html' rel='home' className='main-logo'>
                <img
                  id='logo_header'
                  src='assets/images/logo/logo.png'
                  data-retina='assets/images/logo/logo@2x.png'
                  alt='Logo'
                />
              </a>
              <div className='canvas-nav-close'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='white'
                  x='0px'
                  y='0px'
                  width='20px'
                  height='20px'
                  viewBox='0 0 122.878 122.88'
                  enableBackground='new 0 0 122.878 122.88'
                  xmlSpace='preserve'
                >
                  <g>
                    <path d='M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313L1.426,8.313z' />
                  </g>
                </svg>
              </div>
              <div className='widget-search mt-30'>
                <form
                  action='#'
                  method='get'
                  role='search'
                  className='search-form relative'
                >
                  <input
                    type='search'
                    id='search'
                    className='search-field style-1'
                    placeholder='Search...'
                    value=''
                    name='s'
                    title='Search for'
                    required=''
                  />
                  <button
                    className='search search-submit'
                    type='submit'
                    title='Search'
                  >
                    <i className='icon-search'></i>
                  </button>
                </form>
              </div>
              <div className='widget widget-categories'>
                <h5 className='title-widget'>Categories</h5>
                <ul>
                  <li>
                    <div className='cate-item'>
                      <a href='#'>NFTs</a>
                    </div>
                    <div className='number'>(1.483)</div>
                  </li>
                  <li>
                    <div className='cate-item'>
                      <a href='#'>Digital Art</a>
                    </div>
                    <div className='number'>(97)</div>
                  </li>
                  <li>
                    <div className='cate-item'>
                      <a href='#'>Crypto</a>
                    </div>
                    <div className='number'>(45)</div>
                  </li>
                  <li>
                    <div className='cate-item'>
                      <a href='#'>Technology</a>
                    </div>
                    <div className='number'>(728)</div>
                  </li>
                </ul>
              </div>
              <div className='widget widget-menu style-4'>
                <h5 className='title-widget'>Company</h5>
                <ul>
                  <li>
                    <a href='#'>Help center</a>
                  </li>
                  <li>
                    <a href='#'>Platform status</a>
                  </li>
                </ul>
              </div>
              <div className='widget'>
                <h5 className='title-widget'>Join the community</h5>
                <div className='widget-social'>
                  <ul className='flex'>
                    <li>
                      <a href='#' className='icon-facebook'></a>
                    </li>
                    <li>
                      <a href='#' className='icon-twitter'></a>
                    </li>
                    <li>
                      <a href='#' className='icon-vt'></a>
                    </li>
                    <li>
                      <a href='#' className='icon-tiktok'></a>
                    </li>
                    <li>
                      <a href='#' className='icon-youtube'></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------------------------ */}
        {/* ------------------------------------------------ */}
        <div className='mobile-nav-wrap'>
          <div className='overlay-mobile-nav'></div>
          <div className='inner-mobile-nav'>
            <a href='index.html' rel='home' className='main-logo'>
              <img
                id='mobile-logo_header'
                src='assets/images/logo/logo.png'
                data-retina='assets/images/logo/logo@2x.png'
                alt='Mobile Logo'
              />
            </a>
            <div className='mobile-nav-close'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='white'
                x='0px'
                y='0px'
                width='20px'
                height='20px'
                viewBox='0 0 122.878 122.88'
                enableBackground='new 0 0 122.878 122.88'
                xmlSpace='preserve'
              >
                <g>
                  <path d='M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313L1.426,8.313z' />
                </g>
              </svg>
            </div>
            <nav id='mobile-main-nav' className='mobile-main-nav'>
              <ul id='menu-mobile-menu' className='menu'>
                <li className='menu-item  current-menu-item'>
                  <a className='item-menu-mobile font-family-iranyekan'>خانه</a>
                </li>
                <li className='menu-item'>
                  <a
                    className='item-menu-mobile font-family-iranyekan'
                    href='about-us.html'
                  >
                    دربره ما
                  </a>
                </li>
                <li className='menu-item '>
                  <a className='item-menu-mobile font-family-iranyekan'>
                    محصولات
                  </a>
                  <ul className='sub-menu-mobile'>
                    <li className='menu-item'>
                      <a href='explore-1.html'>Explore Style 1</a>
                    </li>
                    <li className='menu-item'>
                      <a href='explore-2.html'>Explore Style 2</a>
                    </li>
                    <li className='menu-item'>
                      <a href='explore-3.html'>Explore Style 3</a>
                    </li>
                    <li className='menu-item'>
                      <a href='explore-4.html'>Explore Style 4</a>
                    </li>
                    <li className='menu-item'>
                      <a href='nft-detail-1.html'>NFT Detail 1</a>
                    </li>
                    <li className='menu-item'>
                      <a href='nft-detail-2.html'>NFT Detail 2</a>
                    </li>
                    <li className='menu-item'>
                      <a href='nft-detail-3.html'>NFT Detail 3</a>
                    </li>
                    <li className='menu-item'>
                      <a href='ranking.html'>Ranking</a>
                    </li>
                    <li className='menu-item'>
                      <a href='upcoming.html'>Upcoming Projects</a>
                    </li>
                  </ul>
                </li>
                <li className='menu-item '>
                  <a className='item-menu-mobile font-family-iranyekan'>بلاگ</a>
                </li>
                <li className='menu-item'>
                  <a
                    className='item-menu-mobile font-family-iranyekan'
                    href='contact-us.html'
                  >
                    تماس با ما
                  </a>
                </li>
              </ul>
            </nav>
            <div className='widget-search mt-30'>
              <form
                action='#'
                method='get'
                role='search'
                className='search-form relative'
              >
                <input
                  type='search'
                  className='search-field style-1'
                  placeholder='Search...'
                  value=''
                  name='s'
                  title='Search for'
                  required=''
                />
                <button
                  className='search search-submit'
                  type='submit'
                  title='Search'
                >
                  <i className='icon-search'></i>
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* ------------------------------------------------ */}
      </header>
      <Outlet />
    </Fragment>
  )
}

export default Navigation
