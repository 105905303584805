import  './header-banner-img.styles.css';

import GiftCardVector from "../../assets/images/gift-card-vector.png"

const HeaderBannerImg = () => {
  return (
    <div className="header-banner-img-wrapper">
        <img className="header-banner-img" src={GiftCardVector} alt="img" />
    </div>
  )
}

export default HeaderBannerImg