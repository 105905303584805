import React from 'react'

const HeaderTitleSection = () => {
  return (
    <div className='flat-pages-title'>
    {/* ------------------------ */}
    <div className='widget-bg-line'>
      <div className='wraper'>
        <div className='bg-grid-line y bottom'>
          <div className='bg-line'></div>
        </div>
      </div>
    </div>
    {/* ------------------------ */}
    {/* ------------------------ */}
    <div className='themesflat-container w1490'>
      <div className='row'>
        <div className='col-12 pages-title'>
          <div className='content'>
            <h1 data-wow-delay='0s' className='wow fadeInUp'>
              با ما بهترین قیمت ها را تجربه کنید
            </h1>
            <p className='wow fadeInUp' data-wow-delay='0.1s'>
              مجموعه‌ای متنوع از گیفت کارت‌های دیجیتال را ارائه می‌دهد
              که شامل خدمات سرگرمی، بازی‌های آنلاین، فروشگاه‌های جهانی و
              اشتراک‌های محبوب است. با خرید آسان، تحویل سریع و پشتیبانی
              ۲۴ ساعته، بهترین تجربه خرید آنلاین را برای شما فراهم
              می‌کنیم.
            </p>
            <div
              data-wow-delay='0.2s'
              class='wow fadeInUp flat-button flex justify-center'
            >
              <a href='/' class='tf-button style-1 h50 w190 mr-16'>
                راهنمای خرید <i class='icon-arrow-up-right2'></i>
              </a>
              <a href='/' class='tf-button style-1 h50 w190 active'>
                خرید گیفت کارت <i class='icon-arrow-up-right2'></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* ------------------------ */}
  </div>
  )
}

export default HeaderTitleSection