import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './routes/home/home'
import './App.css'
import Footer from './routes/footer/footer.component'
import Navigation from './routes/navigation/navigation.component'
import BlogGrid from './routes/blog-grid/blog.grid'
import BlogDetails from './routes/blog-details/blog.details'

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path='/' element={<Navigation />}>
            <Route path='/' element={<Footer />}>
              <Route index element={<Home />} />
              <Route path='/blog-list' element={<BlogGrid />} />
              <Route path='/blog-details' element={<BlogDetails />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </Router>
  )
}

export default App
