
import  './category-section.styles.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import SwiperCore, { Navigation, Pagination } from 'swiper'

const CategorySection = ({cat}) => {
  return (
    <div className='tf-section seller'>
    <div className='themesflat-container'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='heading-section pb-30'>
            <h2 className='tf-title'>دسته بندی گیفت کارت</h2>
            <a href='explore-3.html'>
              <i className='icon-arrow-right2'></i>
              دیدن محصولات
            </a>
          </div>
        </div>
        <div className='col-md-12'>
          <Swiper
            spaceBetween={30}
            slidesPerView={2}
            navigation={{
              nextEl: '.seller-next',
              prevEl: '.seller-prev',
            }}
            breakpoints={{
              500: { slidesPerView: 3 },
              640: { slidesPerView: 4 },
              768: { slidesPerView: 5 },
              1070: { slidesPerView: 6 },
            }}
            observer={true}
            observeParents={true}
            grabCursor={true}
            loop={false}
            className='seller seller-slider2'
          >
            {cat.map((category, index) => (
              <SwiperSlide key={index}>
                <div className='tf-category text-center'>
                  <div className='card-media'>
                    <img src={category.img} alt={category.title} />
                    <a href='#'>
                      <i className='icon-arrow-up-right2'></i>
                    </a>
                  </div>
                  <h6>{category.title}</h6>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className='swiper-button-next seller-next over active'></div>
          <div className='swiper-button-prev seller-prev over'></div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default CategorySection;