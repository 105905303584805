import React, { useEffect, useCallback, useRef } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import SwiperCore, { Navigation, Pagination } from 'swiper'

import { IoCall } from 'react-icons/io5'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Chart } from 'chart.js'

// style
import './home.css'
import '../../assets/css/style.css'
import '../../assets/css/animate.min.css'
import '../../assets/css/animation.css'
import '../../assets/css/responsive.css'
import '../../assets/css/shortcodes.css'
import '../../assets/css/swiper-bundle.min.css'
// swiper

// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.bundle.min.js'

// image

import img1 from '../../assets/images/avatar/avatar-box-02.jpg'
import logo from '../../assets/images/logo-04.png'

// products 

import pr1 from '../../assets/images/products/1.png'
import pr2 from '../../assets/images/products/2.png'
import pr3 from '../../assets/images/products/3.png'
import pr4 from '../../assets/images/products/4.png'
import pr5 from '../../assets/images/products/5.png'
import pr6 from '../../assets/images/products/6.png'
import pr7 from '../../assets/images/products/7.png'
import pr10 from '../../assets/images/products/10.jpg'
import pr11 from '../../assets/images/products/11.jpg'


// SVG

import svg1 from '../../assets/images/products/SVG/chatgpt-account-zoodgift.svg'
import svg2 from '../../assets/images/products/SVG/google-account-zoodgift.svg'
import svg3 from '../../assets/images/products/SVG/googleplay-gift-zoodgift.svg'
import svg4 from '../../assets/images/products/SVG/playstationstore-gift-zoodgift.svg'
import svg5 from '../../assets/images/products/SVG/plus-account-zoodgift.svg'
import svg6 from '../../assets/images/products/SVG/spotify-account-zoodgift.svg'
import svg7 from '../../assets/images/products/SVG/telegram-gift-zoodgift.svg'
import svg8 from '../../assets/images/products/SVG/xbox-gift-zoodgift.svg'

// js
import WOW from 'wowjs'
import HeaderTitleSection from '../../components/header-title-section/header-title-section.component'
import GiftCardSection from '../../components/gift-card-section/gift-card-section.component'
import StepSection from '../../components/step-section/step-section.component'
import CategorySection from '../../components/category-section/category-section.component'
import LastNewsSection from '../../components/last-news-section/last-news-section.component'
import GuidSection from '../../components/guid-section/guid-section.component'
import FaqSection from '../../components/faq-section/faq-section.component'
import HeaderBanner from '../../components/header-banner/header-banner.component'


const Home = () => {
  // فعال کردن ماژول‌ها
  SwiperCore.use([Navigation, Pagination])

  // WOW
  useEffect(() => {
    const wow = new WOW.WOW({
      live: false, // فقط به المان‌های موجود در DOM اعمال می‌شود
    })
    wow.init()
  }, [])



  const items = [
    {
      img: svg1,
      avatar: img1,
      name: 'گیفت کارت پلی استیشن پلاس',
      postedBy: 'تحویل آنی ',
      bid: '0,34',
      delay: '0s',
    },
    {
      img: svg2,
      avatar: img1,
      name: 'گیفت کارت آیتونز اپل',
      postedBy: 'تحویل زماندار',
      bid: '0,34',
      delay: '0.1s',
    },
    {
      img: svg4,
      avatar: img1,
      name: 'گیفت کارت گیم ایکس باکس',
      postedBy: 'تحویل آنی ',
      bid: '0,34',
      delay: '0.2s',
    },
    {
      img: svg5,
      avatar: img1,
      name: 'اشتراک یک ماهه نتفلیکس',
      postedBy: 'تحویل زماندار',
      bid: '0,34',
      delay: '0.3s',
    },
    {
      img: svg6,
      avatar: img1,
      name: 'اشتراک یک ماهه آمازون پرایم',
      postedBy: 'تحویل آنی ',
      bid: '0,34',
      delay: '0.3s',
    },
    {
      img: svg7,
      avatar: img1,
      name: 'اشتراک یک ماهه اسپاتیفای',
      postedBy: 'تحویل زماندار',
      bid: '0,34',
      delay: '0.3s',
    },
    {
      img: svg8,
      avatar: img1,
      name: 'اشتراک یک ماهه اپن لیبرا',
      postedBy: 'تحویل آنی ',
      bid: '0,34',
      delay: '0.3s',
    },
    {
      img: pr7,
      avatar: img1,
      name: 'اشتراک یک ماهه اپن تلگرام',
      postedBy: 'تحویل زماندار',
      bid: '0,34',
      delay: '0.3s',
    },
    // Add more items as necessary
  ]

  // Live Bidding

  const itemsBidding = [
    {
      id: 1,
      img: pr10,
      name: 'اشتراک یک ماهه اپن تلگرام',
      authorImg: img1,
      authorName: 'تحویل زماندار',
      currentBid: '0,34',
    },
    {
      id: 2,
      img: pr11,
      name: 'اشتراک یک ماهه اپن لیبرا',
      authorImg: img1,
      authorName: 'تحویل آنی ',
      currentBid: '0,34',
    },
    {
      id: 3,
      img: pr5,
      name: 'اشتراک یک ماهه اسپاتیفای',
      authorImg: img1,
      authorName: 'تحویل زماندار',
      currentBid: '0,34',
    },
    {
      id: 4,
      img: pr6,
      name: 'اشتراک یک ماهه آمازون پرایم',
      authorImg: img1,
      authorName: 'تحویل آنی ',
      currentBid: '0,34',
    },
    {
      id: 5,
      img: pr7,
      name: 'اشتراک یک ماهه آمازون پرایم',
      authorImg: img1,
      authorName: 'تحویل زماندار',
      currentBid: '0,34',
    },
  ]

  // Browse by category

  const categories = [
    { img: pr1, title: 'اپل' },
    { img: pr2, title: 'سونی' },
    { img: pr3, title: 'تلگرام' },
    { img: pr4, title: 'اسپاتی فای' },
    { img: pr5, title: 'اکسپرس' },
    { img: pr6, title: 'ایکس باکس' },
    { img: pr7, title: 'Typo' },
    { img: img1, title: 'Typo' },
  ]

  // Step by step Create and Sell Your NFTs

  const steps = [
    {
      id: 1,
      img: img1,
      step: 'قدم اول',
      title: 'با سایت فروش گیفت کارت',
      description:
        ' این پلتفرم جامع، مجموعه‌ای متنوع از گیفت کارت‌های دیجیتال را ارائه می‌دهد',
      link: 'contact-us.html',
    },
    {
      id: 2,
      img: img1,
      step: 'قدم دوم',
      title: 'دنیایی از انتخاب‌ها و امکانات',
      description:
        'که شامل خدمات سرگرمی، بازی‌های آنلاین، فروشگاه‌های جهانی و اشتراک‌های محبوب است.',
      link: 'contact-us.html',
    },
    {
      id: 3,
      img: img1,
      step: 'قدم سوم',
      title: 'در دستان شماست! ',
      description: 'با خرید آسان، تحویل سریع و پشتیبانی ۲۴ ساعته',
      link: 'contact-us.html',
    },
    {
      id: 4,
      img: img1,
      step: 'قدم چهارم',
      title: 'خرید خدمات دیجیتال باشید',
      description: 'بهترین تجربه خرید آنلاین را برای شما فراهم می‌کنیم. ',
      link: 'contact-us.html',
    },
  ]

// progress bar
    const progressStyles = {
      transition: 'stroke-dashoffset 10ms linear 0s',
      strokeDasharray: '307.919, 307.919',
      strokeDashoffset: '286.138',
    }

  return (
    <body className='body'>
      <div id='wrapper'>
        <div id='page' className='pt-40 home-1'>
          <HeaderBanner />
          {/* Section-1 end */}

          {/* Section-2 start */}
          <GiftCardSection cat={items} />
          {/* Section-2 end */}

          {/* section-4 start */}
          <StepSection cat={steps} />
          {/* section-4 end */}

          {/* Section-3 start */}
          <CategorySection cat={categories} />
          {/* Section-3 end */}

          {/* section-5 start */}
          <LastNewsSection cat={itemsBidding} />
          {/* section-5 end  */}

          {/* section-6 start */}
          <GuidSection />
          {/* section-6 end */}
          {/* section-7 start */}

          <FaqSection />

          {/* section-7 end */}
          <div className='progress-wrap active-progress'>
            <svg
              className='progress-circle svg-content'
              width='100%'
              height='100%'
              viewBox='-1 -1 102 102'
            >
              <path
                d='M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98'
                style={progressStyles}
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </body>
  )
}

export default Home
