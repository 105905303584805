import  "./guid-section.styles.css";

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import SwiperCore, { Navigation, Pagination } from 'swiper'

import img1 from '../../assets/images/avatar/avatar-box-02.jpg'


const GuidSection = () => {
  return (
    <div className='tf-section action'>
    <div className='themesflat-container'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='action__body'>
            <div className='tf-tsparticles'>
              <div
                id='tsparticles1'
                data-color='#161616'
                data-line='#000'
              ></div>
            </div>
            <h2>
              انتخابی مطمئن و سریع برای شماست. به دنیای راحتی و تنوع خوش
              آمدید!{' '}
            </h2>
            <div className='flat-button flex'>
              <a href='/' className='tf-button style-2 h50 w190 mr-10'>
                تماس با ما <i className='icon-arrow-up-right2'></i>
              </a>
              <a href='/' className='tf-button style-2 h50 w230'>
                راهنمای خرید گیفت کارت{' '}
                <i className='icon-arrow-up-right2'></i>
              </a>
            </div>
            <div className='bg-home7'>
             

              <Swiper
                loop={true}
                slidesPerView='auto'
                spaceBetween={14}
                direction='vertical'
                speed={10000}
                autoplay={{
                  delay: 0,
                  disableOnInteraction: false,
                  reverseDirection: true,
                }}
                observer={true}
                observeParents={true}
                className='autoslider4reverse'
              >
                <SwiperSlide>
                  <img src={img1} alt='' />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={img1} alt='' />
                </SwiperSlide>
              </Swiper>

              <Swiper
                loop={true}
                slidesPerView='auto'
                spaceBetween={14}
                direction='vertical'
                speed={10000}
                autoplay={{
                  delay: 0,
                  disableOnInteraction: false,
                }}
                observer={true}
                observeParents={true}
                className='autoslider3reverse'
              >
                <SwiperSlide>
                  <img src={img1} alt='' />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={img1} alt='' />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default GuidSection