import './last-news-section.styles.css';
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import LastNewsBox from '../last-news-box/last-news-box.component';

const LastNewsSection = ({cat}) => {
  return (
    <div className='tf-section featured-item style-bottom'>
    <div className='container-100-p'>
      <div className='row'>
        <div className='col-md-12'>
          <div
            className='heading-section pb-20 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            <h2 className='tf-title'>آخرین اخبار</h2>
            <a href='explore-3.html' className=''>
              <i className='icon-arrow-right2'></i>
              رفتن به وبلاگ
            </a>
          </div>
        </div>
        <div className='col-md-12'>
          <Swiper
            modules={[Navigation, Pagination]}
            loop={true}
            slidesPerView={1}
            spaceBetween={30}
            centeredSlides={true}
            navigation={{
              nextEl: '.next-full',
              prevEl: '.prev-full',
            }}
            pagination={{ clickable: true }}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1300: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
          >
            {cat.map((item) => (
              <SwiperSlide key={item.id}>
                <LastNewsBox cat={item} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className='swiper-pagination'></div>
          <div className='swiper-button-next next-full'></div>
          <div className='swiper-button-prev prev-full'></div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default LastNewsSection;