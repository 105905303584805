import GiftCard from "../gift-card/gift-card.component";
import "./gift-card-section.styles.css";

const GiftCardSection = ({cat}) => {

    

    return (
        <div className='tf-section-3 discover-item'>
            <div className='themesflat-container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div
                            className='heading-section pb-30 wow fadeInUp '
                            data-wow-delay='0.1s'
                        >
                            <h2
                                className='tf-title pb-30 wow fadeInUp'
                                data-wow-delay='0.1s'
                            >
                                خرید گیفت کارت
                            </h2>
                        </div>
                    </div>
                    {cat.map((item, index) => (
                       <GiftCard key={index} cat={item} />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default GiftCardSection