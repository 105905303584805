import Step from '../step/step.component';
import './step-section.styles.css';

const StepSection = ({cat}) => {
  return (
    <div className='tf-section create-sell'>
    <div className='themesflat-container'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='heading-section justify-content-center'>
            <h2
              className='tf-title pb-30 wow fadeInUp'
              data-wow-delay='0.1s'
            >
              راهنمای قدم به قدم خرید محصولات
            </h2>
          </div>
        </div>
        {cat.map((step) => (
            <Step key={step.id} cat={step} />
        ))}
      </div>
    </div>
  </div>
  )
}

export default StepSection