import './last-news-box.styles.css';

const LastNewsBox = ({cat}) => {
  return (
    <div className='tf-card-box style-1 last-news-box'>
    <div className='card-media'>
      <a href='#'>
        <img src={cat.img} alt='' />
      </a>
      <span className='wishlist-button icon-heart'></span>
      <div className='featured-countdown'>
        <span
          className='js-countdown'
          data-timer='7500'
          data-labels='d,h,m,s'
        ></span>
      </div>
      <div className='button-place-bid'>
        <a
          href='/'
          data-toggle='modal'
          data-target='#popup_bid'
          className='tf-button'
        >
          <span>خرید محصول</span>
        </a>
      </div>
    </div>
    <h5 className='name'>
      <a href='nft-detail-2.html'>{cat.name}</a>
    </h5>
    <div className='author flex items-center'>
      <div className='avatar'>
        <img src={cat.authorImg} alt='Image' />
      </div>
      <div className='info'>
        <span>مدت زمان تحویل :</span>
        <h6>
          <a href='author-2.html'>{cat.authorName}</a>
        </h6>
      </div>
    </div>
  </div>
  )
}

export default LastNewsBox